import React from "react"
import { Helmet } from "react-helmet"
import { StaticQuery, graphql } from "gatsby"

const SEO = props => (
  <StaticQuery
    query={graphql`
      query MyQuery {
        site {
          siteMetadata {
            siteUrl
          }
        }
      }
    `}
    render={data => {
      let title = props.title || "Great SMSF Rates Start Here"
      let description = props.description || "Great SMSF Rates Start Here"
      let ogimage =
        props.ogimage || data.site.siteMetadata.siteUrl + "/ogimage.jpg"
      return (
        <Helmet>
          <title>{title}</title>
          <meta name="description" content={description} />
          <meta
            name="twitter:card"
            content="Great SMSF Rates Start Here"
          ></meta>
          <link rel="canonical" href={data.site.siteMetadata.siteUrl}></link>
          <meta property="og:type" content="website" />
          <meta property="og:url" content={data.site.siteMetadata.siteUrl} />
          <meta property="og:title" content={title} />
          <meta property="og:description" content={description} />
          <meta property="og:image" content={ogimage} />
          <meta property="twitter:image" content={ogimage} />
        </Helmet>
      )
    }}
  />
)

export default SEO
